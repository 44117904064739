import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const Productgrid = ({ product }) => {
  const [currentgoldprice, setCurrentgoldprice] = useState([]);
  const { isAuthenticated, user } = useSelector((state) => state.authState);
  useEffect(() => {
    const fetchCurrentPrice = async () => {
      try {
        const response = await axios.get(
          "https://igold.teammatrixmantra.com/goldprice/admin/show"
        );

        setCurrentgoldprice(response.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchCurrentPrice();
  }, []);

  console.log(currentgoldprice);
  return (
    <div>
      <Link to={`/product/${product._id}`}>
        <div className="max-w-md mx-auto rounded-md overflow-hidden shadow-md hover:shadow-lg">
          <div className="relative h-96 overflow-hidden">
            <img
              className="w-full"
              src={product.images[0].image}
              alt={product.name}
            />
            <div className="absolute top-0 right-0 bg-red-500 text-white px-2 py-1 m-2 rounded-md text-sm font-medium">
              SALE
            </div>
          </div>
          <div className="p-4">
            <h3 className="text-lg font-medium mb-2">
              {/* <Link to={`/product/${product._id}`}>{product.name}</Link> */}
              {/* {product.name} */}
            </h3>
            <p className="text-gray-600 text-sm mb-4">{product.description}</p>

            <div className="flex items-center justify-between">
              {currentgoldprice.map((price) => (
                <span className="font-bold text-lg">
                  Rs{" "}
                  {(price.goldprice / 8) * product.weight +
                    (price.goldprice * product.makingrate) / 100}
                </span>
              ))}

              <button className="bg-amber-500 hover:bg-amber-600 text-white font-bold py-2 px-4 rounded">
                Buy Now
              </button>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Productgrid;
