import React from 'react'
import { banner1 } from '../../images'
import { banner2 } from '../../images'

const Banner = () => {
  return (
    <section className= "flex md:flex-row flex-col sm:py-16 py-6 pl-[20px] pr-[20px] ">
        <div className="flex-1 flex justify-center items-center  relative pl-[10px] pr-[10px] mt-[3%]">
            <img src={banner1} alt="banner1" className='w-[100%] h-[100%]' />
        </div>
        <div className="flex-1 flex justify-center items-center relative pl-[10px] pr-[10px] mt-[3%]">
            <img src={banner2} alt="banner2" className='w-[100%] h-[100%]' />
        </div>
    </section>
  )
}

export default Banner