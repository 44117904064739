// import React from 'react'
// import { gallery1 } from '../../images'
// import { gallery2 } from '../../images'
// import { gallery3 } from '../../images'
// import { gallery4 } from '../../images'
// import { gallery5 } from '../../images'
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
// // Import Swiper styles
// import 'swiper/swiper-bundle.css'

// const Gallery = () => {
//   return (
//     <div className="md:flex">

//        {/* Swiper slider for mobile screens */}
//        <div className="sm:w-full p-4 sm:hidden">
//         <Swiper
//           spaceBetween={10}
//           slidesPerView={1}
//           navigation
//           pagination={{ clickable: true }}
//         >
//           <SwiperSlide>
//             <img src={gallery1} className="w-full" alt="Slider Image 1" />
//           </SwiperSlide>
//           <SwiperSlide>
//             <img src={gallery2} className="w-full" alt="Slider Image 2" />
//           </SwiperSlide>
//           {/* Add more slides as needed */}
//         </Swiper>
//       </div>

//   <div className="md:w-1/4 sm:w-full p-4">
//     <a href="/gold_jewellery/womens/necklace">
//       <img src={gallery1} className="w-full" alt="Necklace" />
//     </a>
//   </div>
//   <div className="md:w-1/2 sm:w-full">
//     <div className="md:flex">
//       <div className="md:w-1/2 sm:w-full p-4">
//         <a href="">
//           <img src={gallery2} className="w-full" alt="Ring" />
//         </a>
//       </div>
//       <div className="md:w-1/2 sm:w-full p-4">
//         <a href="gold_jewellery/womens/haram">
//           <img src={gallery3} className="w-full" alt="Haram" />
//         </a>
//       </div>
//     </div>
//     <div className="md:w-full sm:w-full p-4">
//       <a href="/gold_jewellery/womens/bangles">
//         <img src={gallery4} className="w-full" alt="Bangle" />
//       </a>
//     </div>
//   </div>
//   <div className="md:w-1/4 sm:w-full p-4">
//     <a href="/gold_jewellery/womens/earrings">
//       <img src={gallery5} className="w-full" alt="Earring" />
//     </a>
//   </div>
// </div>

//   )
// }

// export default Gallery

import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay  } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import { gallery1, gallery2, gallery3, gallery4, gallery5, bangle, necklace, haram, earring, ring } from '../../images';

const Gallery = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767);

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div>
      {isMobile && (
        <div className="sm:w-full p-4">
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, Autoplay]}
            spaceBetween={10}
            slidesPerView={1}
            navigation
            pagination={{ clickable: true }}
            loop={true} 
            scrollbar={{ draggable: true }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            // breakpoints={{
            //   600: {
            //     slidesPerView: 3,
            //   },
             
            // }}
          >
            <SwiperSlide>
              <img src={bangle} className="w-full" alt="Slider Image 1" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={necklace} className="w-full" alt="Slider Image 2" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={haram} className="w-full" alt="Slider Image 3" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={earring} className="w-full" alt="Slider Image 4" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={ring} className="w-full" alt="Slider Image 5" />
            </SwiperSlide>
            {/* Add more slides as needed */}
          </Swiper>
        </div>
      )}
      {!isMobile && (
       <div className="md:flex hidden">
      {/* Render normal images on other devices */}
      <div className="md:w-1/4 sm:w-full p-4">
        <a href="/gold_jewellery/womens/necklace">
          <img src={gallery1} className="w-full" alt="Necklace" />
        </a>
      </div>
      <div className="md:w-1/2 sm:w-full">
        <div className="md:flex">
          <div className="md:w-1/2 sm:w-full p-4">
            <a href="">
              <img src={gallery2} className="w-full" alt="Ring" />
            </a>
          </div>
          <div className="md:w-1/2 sm:w-full p-4">
            <a href="gold_jewellery/womens/haram">
              <img src={gallery3} className="w-full" alt="Haram" />
            </a>
          </div>
        </div>
        <div className="md:w-full sm:w-full p-4">
          <a href="/gold_jewellery/womens/bangles">
            <img src={gallery4} className="w-full" alt="Bangle" />
          </a>
        </div>
      </div>
      <div className="md:w-1/4 sm:w-full p-4">
        <a href="/gold_jewellery/womens/earrings">
          <img src={gallery5} className="w-full" alt="Earring" />
        </a>
      </div>
      </div>
       )}
    </div>
  );
};

export default Gallery;
