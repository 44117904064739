import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateProfile, clearAuthError } from "../../actions/userActions";
import { clearUpdateProfile } from "../../slices/authSlice";

export default function UpdateProfile() {
  const { error, user, isUpdated } = useSelector((state) => state.authState);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [avatar, setAvatar] = useState("");
  const [avatarPreview, setAvatarPreview] = useState("/images/default_avatar.png");
  const dispatch = useDispatch();

  const onChangeAvatar = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatarPreview(reader.result);
        setAvatar(e.target.files[0]);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("avatar", avatar);
    dispatch(updateProfile(formData));
  };

  useEffect(() => {
    console.log("User after update:", user);
    if (user) {
      setName(user.name);
      setEmail(user.email);
      if (user.avatar) {
        setAvatarPreview(user.avatar);
      }
    }

    if (isUpdated) {
      toast("Profile updated successfully", {
        type: "success",
        position: toast.POSITION.BOTTOM_CENTER,
        onOpen: () => dispatch(clearUpdateProfile())
      });
      return;
    }

    if (error) {
      toast(error, {
        position: toast.POSITION.BOTTOM_CENTER,
        type: "error",
        onOpen: () => {
          dispatch(clearAuthError);
        }
      });
      return;
    }
  }, [user, isUpdated, error, dispatch]);

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="bg-white w-full max-w-md p-5 shadow-lg rounded-md">
        <h1 className="text-3xl font-semibold mb-5">Update Profile</h1>

        <form onSubmit={submitHandler} encType="multipart/form-data">
          <div className="mb-4">
            <label htmlFor="name_field" className="block text-sm font-medium text-gray-600">
              Name
            </label>
            <input
              type="name"
              id="name_field"
              className="mt-1 p-2 border border-gray-300 rounded-md w-full"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label htmlFor="email_field" className="block text-sm font-medium text-gray-600">
              Email
            </label>
            <input
              type="email"
              id="email_field"
              className="mt-1 p-2 border border-gray-300 rounded-md w-full"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label htmlFor="avatar_upload" className="block text-sm font-medium text-gray-600">
              Profile
            </label>
            <div className="flex items-center">
              <div className="mr-3">
                <img src={avatarPreview} className="rounded-full" alt="Avatar Preview" />
              </div>
              <div className="custom-file">
                <input
                  type="file"
                  name="avatar"
                  className="custom-file-input"
                  id="customFile"
                  onChange={onChangeAvatar}
                />
                
              </div>
            </div>
          </div>

          <button type="submit" className="bg-yellow-500 text-white p-2 rounded-md w-full">
            Update
          </button>
        </form>
      </div>
    </div>
  );
}


