import React, { Fragment } from 'react'
import Hero from './sections/Hero'
import Banner from './sections/Banner'
import Gallery from './sections/Gallery'
import mypage from './sections/mypage'
import Productgrid from './sections/Productgrid'
import MetaData from '../components/MetaData'
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from '../actions/productActions'
import { useEffect, useState } from "react";
import Loader from '../components/Loader'
import  {toast} from 'react-toastify';

const Home = () => {
  const dispatch = useDispatch();
  const {products, loading, error} = useSelector((state) => state.productsState)
  useEffect(()=>{
    if(error) {
      return toast.error(error,{
          position: toast.POSITION.BOTTOM_CENTER
      })
    }
    dispatch(getProducts) 
  },[error])
  
  //this only based on latest created products
  // const sortedProducts = products && [...products].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  // const latestProducts = sortedProducts && sortedProducts.slice(0, 4);

  const getCategoryProducts = (category) => {
    const categoryProducts = products ? products.filter(product => product.category === category)
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) : [];
    return categoryProducts.length > 0 ? categoryProducts[0] : null;
  };
  
  const categories = ['Rings', 'Earrings', 'Bangles', 'Necklace'];
  const latestCategoryProducts = categories.map(category => getCategoryProducts(category)).filter(Boolean);

  return (
    <Fragment >
      {loading ? <Loader/> :
        <Fragment>
          <MetaData heading={'Home'} />
          <div>
            <Hero/> 
            <Banner/>
            <div className='text-center  mb-10'>
              <h1 className='font-bold text-3xl' >Our Categories</h1>
              <p className='p-1 ml-2'>Reflecting your unique style and celebrating life's Precious moments.</p>
              </div>
            <Gallery/>
            {/* <Productgrid/> */}
            <div className='text-center  mt-10'>
              <h1 className='font-bold text-3xl' >Trending Now</h1>
              <p className='p-1'>Discover the latest trends and must-have styles!</p>
              </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 py-12 px-3">
              {latestCategoryProducts && latestCategoryProducts.slice(0, 4).map(product => (
                <Productgrid key={product._id} product={product}/>
              ))}
            </div>
          
            
            <mypage/>
          </div>
        </Fragment>  
      }
    </Fragment>
    
  )
}

export default Home