import React, { useState, useEffect } from "react";
import axios from "axios";

import Sidebar from "./Sidebar";
import { Link } from "react-router-dom";
const Currentprice = () => {
  const [currentgoldprice, setCurrentgoldprice] = useState([]);
  //get current price from databse
  useEffect(() => {
    const fetchCurrentPrice = async () => {
      try {
        const response = await axios.get(
          "https://igold.teammatrixmantra.com/goldprice/admin/show"
        );
        setCurrentgoldprice(response.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchCurrentPrice();
  }, []);

  return (
    <div className="flex">
      <Sidebar />

      <div className="h-screen flex-1 p-7 text-black overflow-auto md:overflow-x-hidden ">
        <div className="w-3/4 h-2/3  p-10">
          <h1 className="my-4 text-4xl font-bold">Today gold Price</h1>
          <div className="bg-gray-800 text-white   h-24  px-10">
            <div className=" mb-4">
              {currentgoldprice.map((price) => (
                <table>
                  <thead>
                    <tr className="text-slate-300">
                      <th className=" pb-3 text-xl">Today Gold Price</th>
                      <th className=" pl-20 pb-3 text-xl ">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="">
                      <td>{price.goldprice}</td>
                      <td className="pl-[82px] text-2xl ">
                        <Link
                          to={`/admin/update/price/${price._id}`}
                          className="  text-blue-600 hover:text-blue-500 dark:hover:text-gray-300 dark:text-blue-300 mr-3 "
                        >
                          <i class="fas fa-edit"></i>
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Currentprice;
