import React, { Fragment } from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Swal from "sweetalert2";
import { decreaseCartItemQty, increaseCartItemQty, removeItemFriomCart } from '../../slices/cartSlice';
import { MdDelete } from "react-icons/md";

export const Cart = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {items} = useSelector((state)=>state.cartState)
  const {isAuthenticated,user}=useSelector((state)=>state.authState);
  const dispatch = useDispatch();
  console.log(items);
//  increase cart items
 const increaseQty=(item)=>{
 const count = item.quantity;
 if(item.stock ==0 || count >= item.stock) return;
 dispatch(increaseCartItemQty(item.product))
 }

//  decrease cart items
const decreaseQty=(item) =>{
  const count = item.quantity;
  if(count == 1) return;
  dispatch(decreaseCartItemQty(item.product))
}

  const handleClick =() =>{
   
    if (isAuthenticated) {
      navigate('/shipping')
     
         }
         else{
          navigate('/login?redirect=shipping')
         
         }
          
  }

  return (
    <Fragment>
      {isAuthenticated? 
      <Fragment>
        
     
  
  
     
         {items.length === 0 ?
      <div className='md:h-72 h-96 flex items-center justify-center'>
            <h1 className=' text-3xl font-semibold'>Your Cart is Empty</h1>
            
      </div>

       :
       
      <Fragment>
        {items.map((item)=>(
          <div className=''>
    <div className='mt-28  md:mt-10 container xl:max-w-8xl mx-auto px-2 py-4 lg:px-2 lg:py-4 md:h-screen h-full '>
        <h1 className=' text-2xl font-semibold'>Your Cart : {items.length} items</h1>
        
        <div className='grid grid-cols-1 md:grid-cols-3 mt-10 justify-items-start gap-8 '>

  {/* for mobile view */}

<div className='md:hidden  w-full table-auto  md:px-1 px-5'>
 {items.map((item) =>(
<div className='flex flex-row space-x-8 space-y-6 ' >
<img src={item.image} alt='s' className='h-28 w-28'/>
<div className='flex flex-col space-y-3'><p>{item.name}</p>
<p>{item.weight} g</p>
<p>Rs.{(item.price/8 * item.weight) + (item.price*item.makingrate/	100)}</p>
<div className='flex space-x-3'>
<div >
    <button className='bg-amber-500 hover:bg-amber-600 px-2  rounded-l w-6 text-white font-semibold text-lg text-center' onClick={()=>decreaseQty(item)} >-</button>
    <input type='number' className='w-7 ml-5' value={item.quantity} />
    <button className='bg-amber-500 hover:bg-amber-600 px-2  rounded-r w-5 text-white font-semibold text-lg' onClick={()=>increaseQty(item)}>+</button>
  </div>
  <button onClick={() => dispatch(removeItemFriomCart(item.product))} className='text-sm text-black border-2 border-gray-600 rounded-md px-2  hover:text-red-500 bg-slate-300'>Remove</button>
  </div>
  </div>
</div>
))}

  </div>

 {/* for desktop view        */}
  <div className='md:col-span-2 col-span-1 bg-white rounded-xl shadow-xl w-full   sm:block hidden'>

    <table className="w-full table-auto mt-5  ">
      <thead className='bg-black  '>
      <tr className="text-sm text-left text-gray-500 dark:text-gray-400 ">
                      <th className="flex items-center px-6 pb-3 font-medium dark:text-gray-400 md:flex-row flex-col">
                        <span>Product</span>
                      </th>
                      <th className="px-3 pb-3 font-medium">Product Name</th>
                
                      <th className="px-3 pb-3 font-medium">Weight</th>
                      <th className='px-3 pb-3 font-medium'>Price</th>
                      <th className="px-3 pb-3 font-medium">Quantity</th>
                
                      <th className="px-3 pb-3 font-medium">Action</th>
                      </tr>
      </thead>
  
      {items.map((item) =>(
      <tbody>
      <tr className='border-b-2 border-b-primary'>
        <td  className="px-3 py-5 font-medium ">  <img src={item.image} alt='s' className='h-20 w-20'/></td>
        <td  className="px-3 py-5 font-medium "> <p>{item.name}</p></td>
   
        <td  className="px-3 py-5 font-medium "> <p>{item.weight} g</p></td>
        <td  className="px-3 py-5 font-medium "> <p>Rs.{(item.price/8 * item.weight) + (item.price*item.makingrate/	100)}</p></td>
        <td  className="px-3 py-5 font-medium "> 
        <div>
    <button className='bg-amber-500 hover:bg-amber-600 px-4 py-2 rounded-l w-6 text-white font-semibold text-lg text-center' onClick={()=>decreaseQty(item)} >-</button>
    <input type='number' className='w-7 ml-5' value={item.quantity} />
    <button className='bg-amber-500 hover:bg-amber-600 px-4 py-2 rounded-r w-5 text-white font-semibold text-lg' onClick={()=>increaseQty(item)}>+</button>
  </div>
        </td>
        <td  className="px-3 py-5 font-medium ">  <button onClick={() => dispatch(removeItemFriomCart(item.product))} className='text-sm text-black border-2 border-gray-600 rounded-md px-3 py-2 hover:text-red-500 bg-slate-300'>Remove</button></td>
      </tr>
      </tbody>
    ))}
    </table>





</div>
     
      

     
        

            {/* total of order */}
            <div className='w-full md:px-1 px-5 '>
              <div className='bg-white px-5   rounded-xl shadow-xl'>
              <h2 className='text-xl font-semibold text-center mt-5 bg-black text-white '> Order Summary</h2> 
             <div className='flex justify-between mt-5 border-b-2 border-b-primary '>
              <p >Subtotal</p>
              <p>{items.reduce((acc,item) =>(acc + item.quantity),0)}</p>
              </div>
          
              <div className='flex justify-between mt-5 border-b-2 border-b-primary'>
              <p>ESt.Total</p>
              <p>RS.{items.reduce((acc,item) =>(acc + item.quantity*(item.price/8 * item.weight) + (item.price*item.makingrate/	100)),0)}</p>
              </div>
              <div className='flex  justify-center mt-10 '>
              <button className='bg-primary hover:bg-secondary px-8 py-2 rounded-xl mb-3 ' onClick={()=>handleClick()}>Check Out</button>
              </div>
       
              </div>
          
      
      
            </div>

        </div>
    </div>
    </div>
     ))}
        </Fragment>
     
      }
      </Fragment>
  
 


    
      :
      <Fragment>
        <div className='h-96 flex items-center justify-center text-2xl font-bold'>Please Login</div>
      </Fragment>
      }
      
    
     
     
  
    </Fragment>
  )
}
