import React, { useState } from "react";
import Breadcrump from "../components/Breadcrump";
import Hero2 from "../images/hero2.webp";
import { FaLocationDot } from "react-icons/fa6";
import { MdMarkEmailUnread } from "react-icons/md";
import { IoPhonePortrait } from "react-icons/io5";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaUser } from "react-icons/fa";
import { BiWorld } from "react-icons/bi";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import axios from "axios";
import Swal from "sweetalert2";

const ContactUs = () => {
  const [emailData, setEmailData] = useState({
    name: "",
    email: "",
    message: "",
    phone: "",
    country: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmailData({ ...emailData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  //form validation
  const validateForm = () => {
    const errors = {};
    let isValid = true;

    //name required
    if (!emailData.name) {
      errors.name = "Username is required";
      isValid = false;
    }

    if (!emailData.message) {
      errors.message = "Message is required.";
      isValid = false;
    }

    if (!emailData.country) {
      errors.country = "Country is required";
      isValid = false;
    }
    // Validate email
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailData.email) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!emailPattern.test(emailData.email)) {
      errors.email = "Invalid email format";
      isValid = false;
    }

    // Validate phone number
    const phonePattern = /^\d{10}$/; // Assuming a 10-digit phone number format
    if (!emailData.phone) {
      errors.phone = " phone number is required ";
      isValid = false;
    } else if (!phonePattern.test(emailData.phone)) {
      errors.phone = "Invalid phone number format";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  // handle submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      try {
        console.log(emailData);
        axios.post(
          `https://igold.teammatrixmantra.com/sendemail/contact`,
          emailData
        );
        Swal.fire({
          icon: "success",
          title: "success...",
          text: "Email Send Succesfullly!",
        });
      } catch (error) {
        console.error("Error sending email:", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
    }
  };
  return (
    <>
      <Breadcrump
        clName="contact-breadcrump"
        heroImg={Hero2}
        heading="Contact US"
        text="Contact"
      />
      <div className="container xl:max-w-6xl mx-auto px-2 py-4 lg:px-2 lg:py-4  font-footer mt-10  ">
        {/* <div className="grid  grid-cols-1 lg:grid-cols-4 gap-4 text-[#1b3358]  font-bold mt-20 ">
          <div className="bg-[#febd69] h-48 w-full rounded-xl flex flex-col items-center justify-center space-y-3">
            <FaLocationDot className="h-10 w-10 " />
            <p>KKS Road Jaffna</p>
          </div>
          <div className="bg-[#febd69] h-48 w-full rounded-xl">
            <iframe
              className="rounded-xl"
              width="100%"
              height="200"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?width=100%25&amp;height=200&amp;hl=en&amp;q=No-153%20,%20D-10%20uruthirapuram%20kilinochchi+(JewellsOne)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            >
              <a href="https://www.gps.ie/">gps systems</a>
            </iframe>
          </div>
          <div className="bg-[#febd69] h-48 w-full rounded-xl flex flex-col items-center justify-center space-y-3">
            <FaPhoneAlt className="h-10 w-10 " />
            <p>+94- 0212255888</p>
          </div>
          <div className="bg-[#febd69] h-48 w-full rounded-xl flex flex-col items-center justify-center space-y-3">
            <MdEmail className="h-10 w-10" />
            <p>jewellone@gmail.com</p>
          </div>
        </div> */}
        <div className="flex md:flex-row flex-col gap-3">
          <div className="md:w-1/2 w-full  md:ml-4 ml-0 items-center">
            <p className="text-amber-500 font-semibold text-3xl mt-10">
              {" "}
              Let's Talk{" "}
            </p>
            <p className="font-bold text-5xl mt-3">Get In Touch</p>
            <div className="mt-5 ml-5">
              <div className="flex flex-row gap-3 mt-10 ">
                <FaLocationDot className="h-10 w-10 text-amber-500 " />
                <div className="flex flex-col space-y-3">
                  <p className=" font-semibold">VISIT US</p>
                  <p className="text-xl font-bold">KKS Road Jaffna</p>
                </div>
              </div>
              <div className="flex flex-row gap-3 mt-10 ">
                <FaPhoneAlt className="h-10 w-10 text-amber-500" />
                <div className="flex flex-col space-y-3 ">
                  <p className=" font-semibold">HAVE ANY QUESTION?</p>
                  <p className="text-xl font-bold">+94- 0212255888</p>
                </div>
              </div>
              <div className="flex flex-row gap-3 mt-10 ">
                <MdEmail className="h-10 w-10 text-amber-500" />
                <div className="flex flex-col space-y-3">
                  <p className=" font-semibold">SENT MAIL</p>
                  <p className="text-xl font-bold">jewellone@gmail.com</p>
                </div>
              </div>
            </div>
          </div>

          {/* contact us form */}
          <div className=" md:w-1/2 w-full shadow-xl ">
            <form className="  ">
              <div>
                <div className="flex flex-col">
                  <div className="flex relative  items-center space-x-2  w-full mt-4 p-2">
                    <FaUser className=" absolute ml-5 text-gray-800" />
                    <input
                      value={emailData.name}
                      onChange={handleChange}
                      name="name"
                      placeholder="Name"
                      required
                      className={`pl-10 block w-full p-2 rounded border-2 border-gray-300 focus:bg-gray-200 ${
                        errors.name ? "border-red-500" : ""
                      }`}
                    />
                  </div>

                  {errors.name && (
                    <label className="error text-sm text-red-500">
                      {errors.name}
                    </label>
                  )}
                </div>

                <div className="flex flex-col">
                  <div className="flex relative  items-center space-x-2  w-full p-2 mt-2">
                    <MdMarkEmailUnread className=" absolute ml-5 text-gray-800" />
                    <input
                      name="email"
                      value={emailData.email}
                      onChange={handleChange}
                      id="email"
                      type="email"
                      placeholder="Email"
                      required="true"
                      className={`pl-10 block w-full p-2 rounded border-2 border-gray-300 focus:bg-gray-200 ${
                        errors.email ? "border-red-500" : ""
                      }`}
                    />
                  </div>

                  {errors.email && (
                    <label className="error text-sm text-red-500">
                      {errors.email}
                    </label>
                  )}
                </div>
                <div className="flex flex-col">
                  <div className="flex relative  items-center space-x-2  w-full p-2 mt-2">
                    <IoPhonePortrait className=" absolute ml-5 text-gray-800" />
                    <input
                      name="phone"
                      value={emailData.phone}
                      onChange={handleChange}
                      id="phone"
                      type="text"
                      placeholder="Phone"
                      required="true"
                      className={`pl-10 block w-full p-2 rounded border-2 border-gray-300 focus:bg-gray-200 ${
                        errors.phone ? "border-red-500" : ""
                      }`}
                    />
                  </div>

                  {errors.phone && (
                    <label className="error text-sm text-red-500">
                      {errors.phone}
                    </label>
                  )}
                </div>
                <div className="flex flex-col">
                  <div className="flex relative  items-center space-x-2  w-full p-2 mt-2">
                    <BiWorld className=" absolute ml-5 text-gray-800" />
                    <input
                      name="country"
                      value={emailData.country}
                      onChange={handleChange}
                      id="phone"
                      type="text"
                      placeholder="Country"
                      required="true"
                      className={`pl-10 block w-full p-2 rounded border-2 border-gray-300 focus:bg-gray-200 ${
                        errors.country ? "border-red-500" : ""
                      }`}
                    />
                  </div>
                  {errors.country && (
                    <label className="error text-sm text-red-500">
                      {errors.country}
                    </label>
                  )}
                </div>
                <div className=" pl-4 pr-4 mt-2 p-2">
                  <textarea
                    name="message"
                    value={emailData.message}
                    onChange={handleChange}
                    id="message"
                    type="text"
                    placeholder="Message..."
                    className={`pl-10 block w-full p-2 rounded border-2 border-gray-300 focus:bg-gray-200 ${
                      errors.message ? "border-red-500" : ""
                    }`}
                    rows="7"
                    cols="40"
                    required="true"
                  ></textarea>
                </div>
                {errors.message && (
                  <label className="error text-sm text-red-500">
                    {errors.message}
                  </label>
                )}
              </div>
              <div className="flex items-center justify-start p-2">
                <button
                  onClick={handleSubmit}
                  className="p-3 bg-amber-500   mt-6 text-white font-semibold"
                >
                  SEND A MESSAGE
                </button>
              </div>
            </form>
          </div>
        </div>
        {/* google map */}
        <div className="container xl:max-w-full mx-auto  shadow-lg h-auto mt-5 mb-10">
          <iframe
            className="rounded-xl bg-[#febd69] "
            width="100%"
            height="500"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            src="https://maps.google.com/maps?width=100%25&amp;height=500&amp;hl=en&amp;q=No-153%20,%20D-10%20uruthirapuram%20kilinochchi+(JewellsOne)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          >
            <a href="https://www.gps.ie/">gps systems</a>
          </iframe>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
