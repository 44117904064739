import { Fragment, useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { createNewProduct } from "../../actions/productActions";
import { clearError, clearProductCreated } from "../../slices/productSlice";
import { toast } from "react-toastify";

const NewProduct = () => {

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
 
    
    const handleSelectClick = () => {
        setIsDropdownOpen(!isDropdownOpen);
      };
    
      const handleIconClick = (e) => {
        e.stopPropagation(); // Prevent the click from reaching the select element
        setIsDropdownOpen(!isDropdownOpen);
      };
    
    const [name, setName] = useState("");
    const [price, setPrice] = useState("");
    const [weight,setWeight]=useState("");
    const [makingrate,setMakingrate]=useState("");
    const [description, setDescription] = useState("");
    const [category, setCategory] = useState("");
    const [subcategory, setSubCategory] = useState("");
    const [stock, setStock] = useState(0);
    const [images, setImages] = useState([]);
    const [imagesPreview, setImagesPreview] = useState([]);
    const [filteredSubcategories, setFilteredSubcategories] = useState([]);

    const { loading, isProductCreated, error } = useSelector(state => state.productState);

    const categories = [
        'Rings',
        'Earrings',
        'Bangles',
        'Necklace'
    ];

    const subcategories = [
        'LadiesRings',
                'GentsRings',
                'LadiesEarrings',
                'BabyEarrings',
                'LadiesBangles',
                'GirlsBangles',
                'GirlsNecklace'
    ];

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onImagesChange = (e) => {
        const files = Array.from(e.target.files);

        files.forEach(file => {
            const reader = new FileReader();

            reader.onload = () => {
                if (reader.readyState === 2) {
                    setImagesPreview(oldArray => [...oldArray, reader.result])
                    setImages(oldArray => [...oldArray, file])
                }
            }

            reader.readAsDataURL(file)
        })
    }

    const submitHandler = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', name);
        formData.append('price', price);
        formData.append('stock', stock);
        formData.append('description', description);
        formData.append('category', category);
        formData.append('subcategory', subcategory);
        formData.append('weight',weight);
        formData.append('makingrate',makingrate);

        images.forEach(image => {
            formData.append('images', image)
        })

        dispatch(createNewProduct(formData))
    }

    useEffect(() => {
        if (isProductCreated) {
            toast('Product Created Successfully!', {
                type: 'success',
                position: toast.POSITION.BOTTOM_CENTER,
                onOpen: () => dispatch(clearProductCreated())
            })
            navigate('/admin/products')
            return;
        }

        if (error) {
            toast(error, {
                position: toast.POSITION.BOTTOM_CENTER,
                type: 'error',
                onOpen: () => { dispatch(clearError()) }
            })
            return
        }

          // Update filteredSubcategories when category changes
    if (category) {
        const filtered = subcategories.filter((subcat) => subcat.includes(category));
        setFilteredSubcategories(filtered);
        setSubCategory('');
      } else {
        setFilteredSubcategories([]);
      }
 
  
    }, [isProductCreated, error, dispatch,category])

    return (
        
        <div className="flex">
            <div className="w-1/5">
                {/* <Sidebar /> */}
                <Sidebar dashboardHeight="150vh" />
            </div>
            
            <div className="w-4/5">
                <Fragment>
                    <div className="wrapper my-5 mx-4">
                        <form onSubmit={submitHandler} className="shadow-lg" encType='multipart/form-data'>
                            <h1 className="mb-4 text-2xl font-bold">New Product</h1>

                            <div className="mb-6">
                            <label htmlFor="name_field" class="block mb-2 text-sm font-medium dark:text-gray-400">Product Name</label>
                            <input type="text"
                            id="name_field"
                            className="block w-full px-4 py-3 mb-2 text-sm bg-gray-100 border font-normal rounded dark:placeholder-gray-400 dark:text-gray-400 dark:border-gray-800 dark:bg-gray-800"
                            placeholder="Product Name" 
                            onChange={e => setName(e.target.value)}
                            value={name}
                            />
                           
                            </div>

                            <div className="mb-3">
                                <label htmlFor="price_field" class="block mb-2 text-sm font-medium dark:text-gray-400">Price</label>
                                <input type="text"
                                id="price_field"
                                className="block w-full px-4 py-3 mb-2 text-sm bg-gray-100 border font-normal rounded dark:placeholder-gray-400 dark:text-gray-400 dark:border-gray-800 dark:bg-gray-800"
                                placeholder="Product Price" 
                                onChange={e => setPrice(e.target.value)}
                                value={price}
                                />
                               
                            </div>
                            <div className="mb-3">
                                <label htmlFor="price_field" class="block mb-2 text-sm font-medium dark:text-gray-400">weight</label>
                                <input type="text"
                                id="weight"
                                className="block w-full px-4 py-3 mb-2 text-sm bg-gray-100 border font-normal rounded dark:placeholder-gray-400 dark:text-gray-400 dark:border-gray-800 dark:bg-gray-800"
                                placeholder="Product Weight" 
                                value={weight}
                                onChange={e=> setWeight(e.target.value)}/>

                            </div>

                            <div className="mb-3">
                                <label htmlFor="price_field" class="block mb-2 text-sm font-medium dark:text-gray-400">Making rate</label>
                                <input type="text"
                                id="makingrate"
                                className="block w-full px-4 py-3 mb-2 text-sm bg-gray-100 border font-normal rounded dark:placeholder-gray-400 dark:text-gray-400 dark:border-gray-800 dark:bg-gray-800"
                                placeholder="Making Rate"
                                value={makingrate}
                                onChange={e=>setMakingrate(e.target.value)}
                                />

                            </div>

                            <div className="mb-3">
                            <label htmlFor="description_field" class="block mb-2 text-sm font-medium dark:text-gray-400">Description</label>
                            <textarea 
                            type="message" 
                            placeholder="Message.." 
                            id="description_field" 
                            className="block w-full px-4 py-6 leading-tight placeholder-gray-400 bg-gray-100 border rounded dark:placeholder-gray-400 dark:text-gray-400 dark:border-gray-800 dark:bg-gray-800"
                            onChange={e => setDescription(e.target.value)}
                            value={description}></textarea>
                            </div>

                            {/* <div className="form-group">
                                <label htmlFor="category_field">Category</label>
                                <select onChange={e => setCategory(e.target.value)} className="form-control" id="category_field">
                                    <option value="">Select</option>
                                    {categories.map( category => (
                                        <option key={category} value={category}>{category}</option>
                                    ))}
                                </select>
                            </div> */}
{/* 
<div className="mb-6">
<label htmlFor="category_field" className="block mb-2 text-sm font-medium dark:text-gray-400">Category</label>
                                <select 
                                onChange={e => setCategory(e.target.value)} 
                                value={category}
                                className="block w-full px-4 py-3 mb-2 text-sm text-gray-500 placeholder-gray-400 bg-gray-100 border rounded " id="category_field">
                                    <option value="">Select</option>
                                    {categories.map( category => (
                                        <option key={category} value={category}>{category}</option>
                                    ))}
                                </select>
                            </div>



                            <div className="mb-6">
<label htmlFor="subcategory_field" className="block mb-2 text-sm font-medium dark:text-gray-400">SubCategory</label>
                                <select 
                                onChange={e => setSubCategory(e.target.value)}
                                value={subcategory}

                                className="block w-full px-4 py-3 mb-2 text-sm text-gray-500 placeholder-gray-400 bg-gray-100 border rounded " id="category_field">
                                    <option value="">Select</option>
                                    {subcategories.map( subcategory => (
                                        <option key={subcategory} value={subcategory}>{subcategory}</option>
                                    ))}
                                </select>
                            </div> */}


<div>
      <div className="mb-6">
        <label htmlFor="category_field" className="block mb-2 text-sm font-medium dark:text-gray-400">
          Category
        </label>
        <select
          onChange={(e) => setCategory(e.target.value)}
          value={category}
          className="block w-full px-4 py-3 mb-2 text-sm text-gray-500 placeholder-gray-400 bg-gray-100 border rounded "
          id="category_field"
        >
          <option value="">Select</option>
          {categories.map((cat) => (
            <option key={cat} value={cat}>
              {cat}
            </option>
          ))}
        </select>
      </div>

      <div className="mb-6">
        <label htmlFor="subcategory_field" className="block mb-2 text-sm font-medium dark:text-gray-400">
          SubCategory
        </label>
        <select
          onChange={(e) => setSubCategory(e.target.value)}
          value={subcategory}
          className="block w-full px-4 py-3 mb-2 text-sm text-gray-500 placeholder-gray-400 bg-gray-100 border rounded "
          id="subcategory_field"
        >
          <option value="">Select</option>
          {filteredSubcategories.map((subcat) => (
            <option key={subcat} value={subcat}>
              {subcat}
            </option>
          ))}
        </select>
      </div>
    </div>


                            <div className="mb-6">
                            <label htmlFor="stock_field" class="block mb-2 text-sm font-medium dark:text-gray-400">Stock</label>
                        
                                <input
                                type="number"
                                id="stock_field"
                                className="block w-full px-4 py-3 mb-2 text-sm bg-gray-100 border font-normal rounded dark:placeholder-gray-400 dark:text-gray-400 dark:border-gray-800 dark:bg-gray-800"
                                onChange={e => setStock(e.target.value)}
                                value={stock}
                                />
                            </div>

                           

                            {/* ... other form fields ... */}

                            <div className="mb-6">
                                <label className="block mb-2 text-sm font-medium dark:text-gray-400">Images</label>
                                <div className='mt-1 flex items-center space-x-3'>
                                    <div className='flex items-center'>
                                        <label htmlFor='customFile' className='cursor-pointer'>
                                        <span className="block py-2 px-4 border border-indigo-600 rounded-md text-indigo-600 hover:bg-indigo-600 hover:text-white cursor-pointer">
                                             Choose Images
                                        </span>
                                            
                                            <input
                                                type='file'
                                                name='product_images'
                                                className='sr-only'
                                                id='customFile'
                                                multiple
                                                onChange={onImagesChange}
                                            />
                                        </label>
                                    </div>

                                    <div className="block">
                                    <div className='flex items-center space-x-2'>
                                        {imagesPreview.map((image, index) => (
                                            <img
                                                key={index}
                                                src={image}
                                                alt={`Image Preview`}
                                                className="w-14 h-14 object-cover"
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                            </div>

                            <button
                                id="login_button"
                                type="submit"
                                disabled={loading}
                                className="mt-4 py-2 px-4 bg-blue-500 w-full text-white rounded-md hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-800"
                            >
                                CREATE
                            </button>
                        </form>
                    </div>
                </Fragment>
            </div>
         
        </div>
       
    );
}

export default NewProduct;
