import React from 'react'
import './Breadcrump.css'

const Breadcrump = (props) => {
  return (
    
       

        <div className={props.clName} style={{ backgroundImage: `url(${props.heroImg})` }}>
            <div className='breadcrump-overlay pt-[100px] sm:pt-[150px]'>
                <h1 className='text-center text-5xl font-medium  text-white uppercase font-bodoni'>{props.heading}</h1>
                <p className='text-white text-center'>home / {props.text}</p>
            </div>
        </div>

   
  )
}

export default Breadcrump