import React from 'react'
import Breadcrump from '../components/Breadcrump'
import Hero2 from '../images/hero2.webp'
import {fashionImage} from '../images'
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruck } from '@fortawesome/free-solid-svg-icons';

const AboutUs = () => {

  const [productCount, setProductCount] = useState(0);
  const [designCount, setDesignCount] = useState(0);
  const [customerCount, setCustomerCount] = useState(0);
  const [dealerCount, setDealerCount] = useState(0);

  useEffect(() => {
    const productMaxCount = 1000;
    const designMaxCount = 500;
    const customerMaxCount = 500;
    const dealersMaxCount = 300;
    // Update counts every second
    const interval = setInterval(() => {
      setProductCount((prevCount) => (prevCount < productMaxCount ? prevCount + 10 : prevCount));
      setDesignCount((prevCount) => (prevCount < designMaxCount  ? prevCount + 5 : prevCount));
      setCustomerCount((prevCount) => (prevCount < customerMaxCount ? prevCount + 5 : prevCount));
      setDealerCount((prevCount) => (prevCount < dealersMaxCount ? prevCount + 3 : prevCount));
    }, 20);

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  return (
    <>
        <Breadcrump
        clName = "contact-breadcrump"
        heroImg = {Hero2}
        heading = "About Us"
        text = "About Us"
       
        />

<section className="text-gray-600 body-font">
      <div className="container px-10 pt-24  mx-auto flex flex-wrap">
        <div className="flex flex-wrap -mx-4  mb-auto md:w-1/2 content-start sm:pr-10 font-cinzel">
          <div className="w-full sm:p-4 px-4 mb-6">
            <h1 className="font-medium text-2xl sm:text-5xl mb-2 text-gray-900 pb-2">Discover the Artistry: Unveiling the Story Behind Us</h1>
            <div className="leading-relaxed">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eligendi, quod qui? Officiis maiores molestiae delectus dolor perspiciatis quos illum ab alias at, repellat dolorem ut ea quidem placeat perferendis asperiores recusandae! Eos ad nihil et numquam dicta enim eum placeat molestiae, neque optio magnam aut labore voluptate assumenda nemo dolorum.</div>
          </div>
          <div className="p-4 sm:w-1/2  w-full">
            <div className="bg-gray-100 rounded-lg sm:p-12 p-5 border border-gray-300">
            <h2 className="title-font font-medium text-4xl text-center text-gray-900">{productCount}+</h2>
            <p className="leading-relaxed text-center">Products</p>
            </div>
          </div>
          <div className="p-4 sm:w-1/2  w-full">
           <div className="bg-gray-100 rounded-lg sm:p-12 p-5 border border-gray-300">
            <h2 className="title-font font-medium text-4xl text-center text-gray-900">{designCount}+</h2>
            <p className="leading-relaxed text-center">Designs</p>
            </div>
          </div>

          <div className="p-4 sm:w-1/2  w-full">
           <div className="bg-gray-100 rounded-lg sm:p-12 p-5 border border-gray-300">
            <h2 className="title-font font-medium text-4xl text-center text-gray-900">{customerCount}K+</h2>
            <p className="leading-relaxed text-center">Customers</p>
            </div>
          </div>

          <div className="p-4 sm:w-1/2  w-full">
          <div className="bg-gray-100 rounded-lg sm:p-12 p-5 border border-gray-300">
            <h2 className="title-font text-center font-medium text-4xl text-gray-900">{dealerCount}+</h2>
            <p className="leading-relaxed text-center">Dealers</p>
            </div> 
          </div>
        </div>

        <div className="md:w-1/2 w-full pl-10 rounded-lg overflow-hidden mt-6 sm:mt-0">
          <img className="object-cover object-center lg:w-[90%] md:w-[100%] w-full h-full" src={fashionImage} alt="stats" />
        </div>
      </div>
    </section>

    <section className="text-gray-600 body-font">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-col text-center w-full mb-20">
          <h2 className="text-xs text-indigo-500 tracking-widest font-medium title-font mb-1">
            ROOF PARTY POLAROID
          </h2>
          <h1 className="sm:text-3xl text-2xl font-medium title-font text-gray-900">
            Master Cleanse Reliac Heirloom
          </h1>
        </div>
        <div className="flex flex-wrap -m-4">
        <div className="p-4 md:w-1/3">
          <div className="flex rounded-lg h-full bg-gray-100 sm:px-8 sm:py-8 px-4 py-2">
            <div className="w-[100px] h-[100px] mr-[50px] inline-flex items-center justify-center rounded-full  bg-gray-200 flex-shrink-0">
            <FontAwesomeIcon icon={faTruck} size="2x"/>
            </div>
            <div className="flex flex-col justify-center">
              <h2 className="text-gray-900 text-2xl title-font font-cinzel font-bold">Fast Delivery</h2>
              <p className="leading-relaxed text-base">
              On Orders over $100
              </p>
            </div>
          </div>
        </div>

        <div className="p-4 md:w-1/3">
          <div className="flex rounded-lg h-full bg-gray-100 sm:px-8 sm:py-8 px-4 py-2">
            <div className="w-[100px] h-[100px] mr-[50px] inline-flex items-center justify-center rounded-full  bg-gray-200 flex-shrink-0">
            <FontAwesomeIcon icon={faTruck} size="2x"/>
            </div>
            <div className="flex flex-col justify-center">
              <h2 className="text-gray-900 text-2xl title-font font-cinzel font-bold">Fast Delivery</h2>
              <p className="leading-relaxed text-base">
              On Orders over $100
              </p>
            </div>
          </div>
        </div>

        <div className="p-4 md:w-1/3">
          <div className="flex rounded-lg h-full bg-gray-100 sm:px-8 sm:py-8 px-4 py-2 ">
            <div className="w-[100px] h-[100px] mr-[50px] inline-flex items-center justify-center rounded-full  bg-gray-200 flex-shrink-0">
            <FontAwesomeIcon icon={faTruck} size="2x"/>
            </div>
            <div className="flex flex-col justify-center">
              <h2 className="text-gray-900 text-2xl font-cinzel title-font font-bold">Fast Delivery</h2>
              <p className="leading-relaxed text-base">
              On Orders over $100
              </p>
            </div>
          </div>
        </div>

        
          
          {/* Repeat similar blocks for other features */}
        </div>
      </div>
    </section>

        
     </>
  )
}

export default AboutUs