import React, { Fragment } from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProduct, getRelatedProducts } from "../../actions/productActions";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../Loader";
import { Link } from "react-router-dom";
import RelatedProductDetailGrid from "./RelatedProductDetailGrid";
import axios from "axios";
import Swal from "sweetalert2";
import { addCartItem } from "../../actions/cartActions";

const ProductDetail = () => {
  const { loading, product = {} } = useSelector((state) => state.productState);
  const { isAuthenticated, user } = useSelector((state) => state.authState);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [currentgoldprice, setCurrentgoldprice] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [prduct_price, setProduct_price] = useState();
  const navigate = useNavigate();
  const userId = useSelector((state) => state.authState.user?._id);
  const productId = useSelector((state) => state.productState.product?._id);
  const itemid = useSelector((state) => state.cartState.items?.product);

  {
    /* increse quantity */
  }
  const increseQty = () => {
    const count = document.querySelector(".count");
    if (product.stock == 0 && count.valueAsNumber >= product.stock) return;
    const qty = count.valueAsNumber + 1;
    setQuantity(qty);
  };

  {
    /* decrese quanty */
  }
  const decreseQty = () => {
    const count = document.querySelector(".count");
    if (count.valueAsNumber == 1) return;
    const qty = count.valueAsNumber - 1;
    setQuantity(qty);
  };

  {
    /*get gold price*/
  }
  useEffect(() => {
    const fetchCurrentPrice = async () => {
      try {
        const response = await axios.get(
          "https://igold.teammatrixmantra.com/goldprice/admin/show"
        );

        setCurrentgoldprice(response.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchCurrentPrice();
  }, []);

  const [relatedProducts, setRelatedProducts] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch the details of the clicked product and related products
        const [productResponse, relatedResponse] = await Promise.all([
          dispatch(getProduct(id)),
          dispatch(getRelatedProducts(product.category, id)),
        ]);

        console.log("Product Response:", productResponse); // Log the entire response
        console.log("Related Products Response:", relatedResponse); // Log the entire response
        console.log(
          "try to get the format Related Products Response.data :",
          relatedResponse.data
        ); // Log the entire response

        // Extract the related products data
        const relatedProductsData = Array.isArray(relatedResponse.data.products)
          ? relatedResponse.data.products
          : [];

        console.log(
          "Related Products Data Array Conversion:",
          relatedProductsData
        ); // Log the extracted data

        const shuffleArray = (array) => {
          // Use the Fisher-Yates shuffle algorithm
          for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
          }
          return array;
        };
        // Update the state
        // setRelatedProducts(relatedProductsData);
        setRelatedProducts(shuffleArray(relatedProductsData));
        console.log("Related Products in the use state", relatedProducts);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, [dispatch, id, product.category]);

  const handleClick = (id, price, quantity) => {
    if (!isAuthenticated) {
      navigate(`/login?redirect=/igold.lk/product/${productId}`);
    } else {
      // If user is logged in, proceed with adding item to cart
      //console.log('Add to cart clicked');
      // Add logic to add item to cart with user ID

      try {
        if (itemid == id) {
          Swal.fire("this Item alredy Added To your Cart");
        }
        dispatch(addCartItem(id, price, quantity, userId));
        Swal.fire("Item Added To Cart");
        console.log("Product ID:", productId); // Log the product ID
        console.log("Price:", price); // Log the price
        console.log("Quantity:", quantity); // Log the quantity
        console.log("User ID:", userId); // Log the userId
      } catch (error) {
        console.log(error);
      }
    }
  };
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <div className="">
          {currentgoldprice.map((price) => (
            <section className="py-10 font-poppins ">
              <div className="max-w-6xl px-4 mx-auto ">
                <div className="flex flex-wrap mb-24 -mx-4">
                  <div className="w-full px-4 mb-8 md:w-1/2 md:mb-0">
                    <div className="sticky top-0 overflow-hidden ">
                      <div className="relative mb-6 lg:mb-10 lg:h-96">
                        <a
                          className="absolute left-0 transform lg:ml-2 top-1/2 translate-1/2"
                          href="#"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="w-5 h-5 text-blue-500 bi bi-chevron-left dark:text-blue-200"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                            ></path>
                          </svg>
                        </a>
                        {/* <img className="object-contain w-full lg:h-full" src="https://i.postimg.cc/0N4Kk1PN/black-microprocessors-removebg-preview.png"alt="" /> */}
                        {product.images && product.images.length > 0 && (
                          <img
                            className="object-contain w-full lg:h-full"
                            src={product.images[0].image}
                            alt=""
                          />
                        )}
                        <a
                          className="absolute right-0 transform lg:mr-2 top-1/2 translate-1/2"
                          href="#"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="w-5 h-5 text-blue-500 bi bi-chevron-right dark:text-blue-200"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                            ></path>
                          </svg>
                        </a>
                      </div>

                      {/* Render related products */}
                      <div className="flex-wrap hidden -mx-2 md:flex">
                        {relatedProducts
                          .slice(0, 4)
                          .map((relatedProduct, index) => (
                            <div key={index} className="w-1/2 p-2 sm:w-1/4">
                              <div className="block border border-gray-200 hover:border-blue-400 dark:border-gray-700 dark:hover:border-blue-300">
                                <Link to={`/product/${relatedProduct._id}`}>
                                  <img
                                    className="object-contain w-full lg:h-28"
                                    src={relatedProduct.images[0].image}
                                    alt=""
                                  />
                                </Link>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div className="w-full px-4 md:w-1/2">
                    <div className="lg:pl-20">
                      <div className="mb-6 ">
                        <span className="px-2.5 py-0.5 text-xs text-blue-600 bg-blue-100 dark:bg-gray-700 rounded-xl dark:text-gray-200">
                          New Arrival
                        </span>
                        <h2 className="max-w-xl mt-6 mb-6 text-xl font-semibold leading-loose tracking-wide text-gray-700 md:text-2xl dark:text-gray-300">
                          {product.name}
                        </h2>
                        <div className="flex flex-wrap items-center mb-6">
                          <div className="flex mb-4 mr-2 lg:mb-0">
                            <div className="rating-outer">
                              <div
                                className="rating-inner"
                                style={{
                                  width: `${
                                    ((Math.floor(product.ratings) +
                                      (product.ratings % 1 !== 0 ? 0.5 : 0)) /
                                      5) *
                                    100
                                  }%`,
                                }}
                              ></div>
                            </div>
                          </div>
                          <p className="mb-4 text-xs lg:mb-0" href="#">
                            ( {product.numOfReviews} ratings )
                          </p>
                        </div>
                        <p className="inline-block text-2xl font-semibold text-gray-700 dark:text-gray-400 ">
                          {/* (price.goldprice/8 * product.weight) + (price.goldprice*product.makingrate/	100) */}
                          <span>
                            Rs.
                            {(price.goldprice / 8) * product.weight +
                              (price.goldprice * product.makingrate) / 100}
                          </span>
                          {/* <span className="ml-3 text-base font-normal text-gray-500 line-through dark:text-gray-400">Rs.10,000.00</span> */}
                        </p>
                        <div className="mt-6">
                          <p className="inline-block text-2xl font-semibold text-gray-700 dark:text-gray-400 ">
                            <span>Weight:{product.weight} g</span>
                            {/* <span className="ml-3 text-base font-normal text-gray-500 line-through dark:text-gray-400">Rs.10,000.00</span> */}
                          </p>
                        </div>
                      </div>

                      <div className="py-6 mb-6 border-t border-b border-gray-200 dark:border-gray-700">
                        <span className="text-base font-bold text-gray-600 dark:text-gray-400">
                          <span
                            className={
                              product.stock > 0 ? "greenColor" : "redColor"
                            }
                            id="stock_status"
                          >
                            {product.stock > 0 ? "In Stock" : "Out of Stock"}
                          </span>
                        </span>
                      </div>
                      <div className="pb-6 border-b border-gray-200 dark:border-gray-700">
                        <span className="text-base font-bold text-gray-600 dark:text-gray-400">
                          Description
                        </span>
                        <p className="text-gray-600 dark:text-gray-400">
                          {product.description}
                        </p>
                      </div>
                      <div className="mb-6 "></div>
                      <div className="flex flex-wrap items-center mb-6">
                        <div className="mb-4 mr-4 lg:mb-0">
                          <div className="md:w-28 w-full">
                            <div className="relative flex flex-row w-full h-10 bg-transparent rounded-lg md:justify-start justify-center">
                              <button
                                className="md:w-20 w-full h-full text-white  border-l rounded-l outline-none cursor-pointer bg-amber-500 hover:bg-amber-600"
                                onClick={decreseQty}
                              >
                                <span className="m-auto text-2xl font-bold">
                                  -
                                </span>
                              </button>
                              <input
                                type="number"
                                className="count text-center w-full pl-3 font-semibold  text-gray-700 placeholder-gray-700   bg-slate-200 text-md hover:text-black"
                                value={quantity}
                              />
                              <button
                                className="md:w-20  w-full h-full text-white  border-l rounded-r outline-none cursor-pointer bg-amber-500 hover:bg-amber-600"
                                onClick={increseQty}
                              >
                                <span className="m-auto text-2xl font-bold">
                                  +
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="mb-4 lg:mb-0"></div>

                        <button
                          onClick={() =>
                            handleClick(product._id, price.goldprice, quantity)
                          }
                          className="w-full px-4 py-3 text-center text-black-600  bg-amber-500 lg:w-1/2 rounded-xl"
                          disabled={product.stock == 0 ? true : false}
                        >
                          Add to cart
                        </button>
                      </div>
                      {/* <div className="flex gap-4 mb-6">
                  <a href="#" className="w-full px-4 py-3 text-center text-gray-100 bg-blue-600 border border-transparent dark:border-gray-700 hover:border-blue-500 hover:text-blue-700 hover:bg-blue-100 dark:text-gray-400 dark:bg-gray-700 dark:hover:bg-gray-900 rounded-xl">
                      Buy now
                  </a>
              </div> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 py-12 px-3 ">
                {relatedProducts.slice(0, 4).map((relatedProduct, index) => (
                  <RelatedProductDetailGrid
                    key={relatedProduct._id}
                    product={relatedProduct}
                    currentgoldprice={price.goldprice}
                  />
                ))}
              </div>
            </section>
          ))}
        </div>
      )}
    </Fragment>
  );
};

export default ProductDetail;
