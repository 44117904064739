import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserOrder } from "../../actions/orderAction";

const Userorder = () => {
  const { userOrders = [] } = useSelector((state) => state.orderState);
  const userId = useSelector((state) => state.authState.user?._id);
  console.log(userId);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserOrder(userId));
  }, [dispatch]);
  console.log(userOrders);

  return (
    <div className="mt-10 container xl:max-w-6xl mx-auto px-2 py-4 lg:px-2 lg:py-4   h-full ">
      <h1 className="md:text-5xl text-2xl font-semibold md:mt-5 mt-20 text-center">
        Thank you for your purchse!
      </h1>
      {userOrders.map((order) => (
        <div className="flex flex-col md:flex-row  mt-5 mb-5 space-x-3  border-b-2 border-b-primary pb-2">
          <div className=" w-full md:w-1/2 px-5 mb-10  ">
            <p className="font-bold text-xl mt-5 py-5">Billing Address</p>

            <div className=" bg-gray-100 shadow-md rounded-xl p-3 md:w-3/4 w-full flex flex-col space-y-3 ">
              <div className="flex flex-row space-x-8">
                <p className="font-bold">Name:</p>
                <p>{order.shippingInfo.fullname}</p>
              </div>
              <div className="flex flex-row space-x-8">
                <p className="font-bold">Email:</p>
                <p>{order.shippingInfo.email}</p>
              </div>
              <div className="flex flex-row space-x-7">
                <p className="font-bold">Phone:</p>
                <p>{order.shippingInfo.phone_No}</p>
              </div>
              <div className="flex flex-row space-x-5">
                <p className="font-bold">Address:</p>
                <p>
                  No-{order.shippingInfo.house_No},{order.shippingInfo.street},
                  {order.shippingInfo.city},<br />
                  {order.shippingInfo.district}
                </p>
              </div>
            </div>
          </div>
          <div className=" w-full md:w-1/2 ">
            <div className="md:w-2/6 w-[90%] bg-gray-100 h-6 mt-5 rounded-2xl absolute "></div>
            <div className="md:w-[80%] w-[84%]   bg-gray-50 relative mt-8 md:ml-4 ml-5 p-5">
              <h2 className="text-xl p-5 font-bold border-b-2 border-b-slate-500">
                Order Summary
              </h2>
              <div
                className=" flex flex-row justify-between md:p-5 p-2 items-center
           border-b-2 border-b-slate-500 border-dashed "
              >
                <div className="border-l-2 border-l-slate-500 md:px-5 px-1 ">
                  <p className="text-gray-500 font-semibold">Date</p>
                  <p className="font-bold">{String(order.createdAt).substring(0, 10)}</p>
                </div>
                <div className="border-l-2 border-l-slate-500 md:pl-10 pl-2 md:pr-5 pr-1 border-r-2 border-r-slate-500 ">
                  <p className="text-gray-500 font-semibold">Order ID</p>
                  <p className="font-bold">{order.OrderId}</p>
                </div>
              </div>
              {order.orderItems.map((item) => (
                <div className="md:p-5 py-5 flex flex-row ">
                  <div className="flex md:flex-row  flex-col justify-between space-x-5 text-sm">
                  <img
                                        src={item.image}
                                        alt="product"
                                        className="h-24 w-24 rounded-xl"
                                      />
                                      <div className="flex flex-col space-y-1">
                                      <p className="font-bold text-xs">ID:  {item.product}</p>
                                        <p className="text-gray-500">{item.name}</p>
                                        <p className="text-gray-500">{item.weight}g</p>
                                       
                                        <p className="text-gray-500">{item.quantity} item</p>
                                      </div>
                                      <p className="text-gray-500 font-semibold">
                                          Rs:
                                          {(item.price / 8) * item.weight +
                                            (item.price * item.makingrate) /
                                              100}
                                        </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Userorder;
