import { useState } from "react";
import { Link } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import { useEffect } from "react";
import { getAdminProducts } from "../../actions/productActions";
import Sidebar from "./Sidebar";
const Dashboard = () => {

  const { products = [] } = useSelector( state => state.productsState);
  return (
    <div className="flex">
      <Sidebar/>
      <div className="h-screen flex-1 lg:p-7 text-black">
        {/* <h1 className="text-2xl font-semibold ">Home Page</h1> */}
        <h1 className="my-4 text-4xl font-bold">Dashboard</h1>
<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-10">
    {/* <div className="col-span-1">
        <div className="bg-blue-500 text-white p-16 rounded-lg">
            <div className="text-center text-2xl">
                Total Amount<br />
                
            </div>
        </div>
    </div> */}

    <div className="col-span-1">
        <div className="bg-blue-500 text-white p-1 lg:p-16 rounded-lg">
            <div className="text-center text-2xl">
                Products<br />
                <b>{products.length}</b>
            </div>
            <Link to="/admin/products" className="block text-white mt-2 pl-[88px]">
                View Details <i className="fas fa-angle-right ml-1"></i>
            </Link>
        </div>
    </div>

    <div className="col-span-1">
        <div className="bg-blue-500 text-white p-16 rounded-lg">
            <div className="text-center text-2xl">
                Users<br />
                <b>0</b>
            </div>
            <Link to="/admin/users" className="block text-white mt-2 pl-[88px]">
                View Details <i className="fas fa-angle-right ml-1"></i>
            </Link>
        </div>
    </div>

    <div className="col-span-1">
        <div className="bg-blue-500 text-white p-16 rounded-lg">
            <div className="text-center text-2xl">
                Orders<br />
                <b>0</b>
            </div>
            <Link to="/admin/orders" className="block text-white mt-2 pl-[88px]">
                View Details <i className="fas fa-angle-right ml-1"></i>
            </Link>
        </div>
    </div>

   

    {/* <div className="col-span-1">
        <div className="bg-blue-500 text-white p-16 rounded-lg">
            <div className="text-center text-2xl">
                Out of Stock<br />
                <b>No out of stock products</b>
            </div>
        </div>
    </div> */}
</div>

      </div>
    </div>
  );
};
export default Dashboard;