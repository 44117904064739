import { useEffect, useState } from 'react';
import { updatePassword as updatePasswordAction, clearAuthError } from '../../actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const UpdatePassword = () => {
    const [password, setPassword] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const dispatch = useDispatch();
    const { isUpdated, error } = useSelector(state => state.authState);

    const submitHandler = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('oldPassword', oldPassword);
        formData.append('password', password);
        dispatch(updatePasswordAction(formData));
    }

    useEffect(() => {
        if (isUpdated) {
            toast('Password updated successfully', {
                type: 'success',
                position: toast.POSITION.BOTTOM_CENTER
            });
            setOldPassword("");
            setPassword("");
            return;
        }
        if (error) {
            toast(error, {
                position: toast.POSITION.BOTTOM_CENTER,
                type: 'error',
                onOpen: () => { dispatch(clearAuthError()); }
            });
            return;
        }
    }, [isUpdated, error, dispatch]);

    return (
        <div className="flex items-center justify-center my-10 mx-5 md:mx-0">
            <div className="bg-yellow-500 w-full max-w-md p-8 shadow-lg rounded-md">
                <form onSubmit={submitHandler}>
                    <h1 className="mt-2 mb-5 text-2xl font-bold">Update Password</h1>
                    <div className="mb-4">
                        <label htmlFor="old_password_field" className="block text-sm font-medium text-gray-600">Old Password</label>
                        <input
                            type="password"
                            id="old_password_field"
                            className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                        />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="new_password_field" className="block text-sm font-medium text-gray-600">New Password</label>
                        <input
                            type="password"
                            id="new_password_field"
                            className="mt-1 p-2 border border-gray-300 rounded-md w-full"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>

                    <button type="submit" className="bg-blue-500 text-white p-2 rounded-md w-full">Update Password</button>
                </form>
            </div>
        </div>
    );
}

export default UpdatePassword;
