export const links = [
    {
      name: "Jewellery",
      submenu: true,
      sublinks: [
        {
          Head: "Rings",
          sublink: [
            { name: "Ladies Rings", link: "/products/LadiesRings" },
            { name: "Gents Rings", link: "/products/GentsRings" },
         
          ],
        },
        {
          Head: "Bangles",
          sublink: [
            { name: "Ladies Bangles", link: "/products/LadiesBangles" },
            { name: "Kids Bangles", link: "/products/GirlsBangles" },
          ],
        },
        {
          Head: "Earrings",
          sublink: [
            { name: "Ladies Earrings", link: "/products/LadiesEarrings" },
            { name: "Kids Earrings", link: "/products/BabyEarrings" },

          ],
        },
  
        {
          Head: "Necklaces",
          sublink: [
            { name: "Ladies Necklace", link: "/products/GirlsNecklace" },
            { name: "Kids Necklace", link: "/" },
          ],
        },
        // {
        //   Head: "footwear",
        //   sublink: [
        //     { name: "T-shirt", link: "/" },
        //     { name: "Casual shirts", link: "/" },
        //     { name: "formal shirts", link: "/" },
        //     { name: "formal shirts", link: "/" },
        //     { name: "formal shirts", link: "/" },
        //   ],
        // },
      ],
    },
    // {
    //   name: "Women",
    //   submenu: true,
    //   sublinks: [
    //     {
    //       Head: "Topwear",
    //       sublink: [
    //         { name: "T-shirt", link: "/" },
    //         { name: "Casual shirts", link: "/" },
    //         { name: "formal shirts", link: "/" },
    //         { name: "formal shirts", link: "/" },
    //         { name: "formal shirts", link: "/" },
    //       ],
    //     },
    //     {
    //       Head: "Bottomwear",
    //       sublink: [
    //         { name: "T-shirt", link: "/" },
    //         { name: "Casual shirts", link: "/" },
    //         { name: "formal shirts", link: "/" },
    //         { name: "formal shirts", link: "/" },
    //         { name: "formal shirts", link: "/" },
    //       ],
    //     },
    //     {
    //       Head: "innerwear",
    //       sublink: [
    //         { name: "T-shirt", link: "/" },
    //         { name: "Casual shirts", link: "/" },
    //         { name: "formal shirts", link: "/" },
    //         { name: "formal shirts", link: "/" },
    //         { name: "formal shirts", link: "/" },
    //       ],
    //     },
  
    //     {
    //       Head: "sleepwear",
    //       sublink: [
    //         { name: "T-shirt", link: "/" },
    //         { name: "Casual shirts", link: "/" },
    //         { name: "formal shirts", link: "/" },
    //         { name: "formal shirts", link: "/" },
    //         { name: "formal shirts", link: "/" },
    //       ],
    //     },
    //     {
    //       Head: "footwear",
    //       sublink: [
    //         { name: "T-shirt", link: "/" },
    //         { name: "Casual shirts", link: "/" },
    //         { name: "formal shirts", link: "/" },
    //         { name: "formal shirts", link: "/" },
    //         { name: "formal shirts", link: "/" },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   name: "Kid's",
    //   submenu: true,
    //   sublinks: [
    //     {
    //       Head: "Topwear",
    //       sublink: [
    //         { name: "T-shirt", link: "/" },
    //         { name: "Casual shirts", link: "/" },
    //         { name: "formal shirts", link: "/" },
    //         { name: "formal shirts", link: "/" },
    //         { name: "formal shirts", link: "/" },
    //       ],
    //     },
    //     {
    //       Head: "Bottomwear",
    //       sublink: [
    //         { name: "T-shirt", link: "/" },
    //         { name: "Casual shirts", link: "/" },
    //         { name: "formal shirts", link: "/" },
    //         { name: "formal shirts", link: "/" },
    //         { name: "formal shirts", link: "/" },
    //       ],
    //     },
    //     {
    //       Head: "innerwear",
    //       sublink: [
    //         { name: "T-shirt", link: "/" },
    //         { name: "Casual shirts", link: "/" },
    //         { name: "formal shirts", link: "/" },
    //         { name: "formal shirts", link: "/" },
    //         { name: "formal shirts", link: "/" },
    //       ],
    //     },
  
    //     {
    //       Head: "sleepwear",
    //       sublink: [
    //         { name: "T-shirt", link: "/" },
    //         { name: "Casual shirts", link: "/" },
    //         { name: "formal shirts", link: "/" },
    //         { name: "formal shirts", link: "/" },
    //         { name: "formal shirts", link: "/" },
    //       ],
    //     },
    //     {
    //       Head: "footwear",
    //       sublink: [
    //         { name: "T-shirt", link: "/" },
    //         { name: "Casual shirts", link: "/" },
    //         { name: "formal shirts", link: "/" },
    //         { name: "formal shirts", link: "/" },
    //         { name: "formal shirts", link: "/" },
    //       ],
    //     },
    //   ],
    // },
  ];