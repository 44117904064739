import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader'

const Profile = () => {
  const { user, loading } = useSelector((state) => state.authState);


  return (
    <Fragment>
    {loading ? <Loader/> :
    <section className="flex items-center bg-gray-50 lg:h-screen font-poppins dark:bg-gray-900">
      <div className="justify-center max-w-full px-4 py-4 mx-auto lg:py-0">
        <div className="grid grid-cols-6 gap-6 lg:grid-cols-6 md:grid-cols-6">
          <div className="bg-white rounded-lg shadow-md dark:bg-gray-800 lg:w-[500px]">
            <div className="overflow-hidden rounded-t-lg">
              <img
                // src="https://i.postimg.cc/Qdhgyp8g/second.jpg"
                src="https://i.postimg.cc/7hBDr1vC/pexels-rodnae-productions-7005064.jpg"
                alt=""
                className="object-cover object-top w-full h-48"
              />
            </div>
            <div className="relative w-32 h-32 mx-auto -mt-16 overflow-hidden rounded-full">
              {user && user.avatar ? (
                <img src={user.avatar} alt="" className="object-cover object-top w-full h-32" />
              ) : (
                <img
                  src="./images/default_avatar.png"
                  alt=""
                  className="object-cover object-top w-full h-32"
                />
              )}
            </div>

            <div className="p-4">
              <div className="text-center">
                <h2 className="text-xl font-bold dark:text-gray-400">
                  {user && user.name ? user.name : 'Default Name'}
                </h2>
                <p className="mt-1 text-base text-gray-500 dark:text-gray-400">
                  {user && user.email ? user.email : 'Default Email'}
                </p>
                <p className="mt-1 text-base text-gray-500 dark:text-gray-400">
                  Joined On {user && user.createdAt ? String(user.createdAt).substring(0, 10) : 'N/A'}
                </p>
              </div>

              {/* <div className="flex justify-center px-2 mt-4">
                <Link to="/myprofile/update"
                  className="w-full px-4 py-2 text-center text-gray-100 bg-pink-800 rounded-full hover:bg-pink-700"
                >
                  Update Profile
                </Link>
              </div> */}
                <div className='hidden md:block'>
                <div className="flex justify-center px-2 mt-4 ">
                  <Link to="/myprofile/update"
                    className="w-full px-4 py-2 mr-2 text-center text-gray-100 bg-pink-800 rounded-full hover:bg-pink-700"
                  >
                    {/* <span >
      Update <br className="block md:hidden"/> Profile
    </span> */}
    Update Profile
                  </Link>
              

              
                <Link to="/myprofile/update/password"
                  className="w-full px-4 py-2 text-center text-gray-100 bg-pink-800 rounded-full hover:bg-pink-700"
                >
                  Change password
                </Link>
              </div>
                </div>
               

              <div className='block md:hidden'>
              <div className="flex justify-center px-2 mt-4">
                <Link to="/myprofile/update"
                  className="w-full px-4 py-2 text-center text-gray-100 bg-pink-800 rounded-full hover:bg-pink-700"
                >
                  Update Profile
                </Link>
              </div>
              <div className="flex justify-center px-2 mt-4">
                <Link to="/myprofile/update"
                  className="w-full px-4 py-2 text-center text-gray-100 bg-pink-800 rounded-full hover:bg-pink-700"
                >
                  Change Password
                </Link>
              </div>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </section>
}
</Fragment>
  );
};

export default Profile;
