import axios from "axios";
import React, { useState } from "react";
import Swal from "sweetalert2";

export const Return = () => {
    const [returnDetails,setReturnDetails] = useState({
        orderId :"",
        product:"",
        productId:"",
        name:"",
        phoneno:"",
        email:"",
        address:"",

    })

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
      const { name, value } = e.target;
      setReturnDetails({ ...returnDetails, [name]: value });
      setErrors({ ...errors, [name]: "" });
    };

    const validateForm = () => {
        const errors = {};
        let isValid = true;
    
        //name required
        if (!returnDetails.name) {
          errors.name = "Username is required";
          isValid = false;
        }
    
        if (!returnDetails.orderId) {
          errors.orderid = "Order Id is required.";
          isValid = false;
        }
    
        if (!returnDetails.product) {
          errors.product = "product is required";
          isValid = false;
        }

        if(!returnDetails.productId){
            errors.productId= "product Id is required";
            isValid=false
        }

        if(!returnDetails.address){
            errors.address ="Address is required";
            isValid=false;
        }
        // Validate email
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!returnDetails.email  ) {
          errors.email = "Email is required";
          isValid = false;
        }
        else if(!emailPattern.test(returnDetails.email)){
          errors.email = "Invalid email format";
          isValid = false;
        }
    
        // Validate phone number
        const phonePattern = /^\d{10}$/; // Assuming a 10-digit phone number format
        if (!returnDetails.phoneno ) {
          errors.phoneno = " phone number is required ";
          isValid = false;
        }
        else if(!phonePattern.test(returnDetails.phoneno)){
          errors.phoneno = "Invalid phone number format";
          isValid = false;
        }
    
        setErrors(errors);
        return isValid;
      };


      const handleSubmit = (e) =>{
      
         e.preventDefault();
        const isValid = validateForm();
        if(isValid){
            try {
                axios.post('https://igold.teammatrixmantra.com/return/returnorder', returnDetails);
                Swal.fire({
                    icon: "success",
                    title: "success...",
                    text: "Email Send Succesfullly!",
                  });
            } catch (error) {
                console.error("Error sending email:", error);
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                });
            }
          
            
        }
       
      }
  return (
    <div className="mt-10  md:mt-10 container xl:max-w-6xl mx-auto px-2 py-4 lg:px-2 lg:py-4 h-full ">
      <div className="bg-white shadow-2xl p-5 rounded-md">

      <form >
        <div className="flex md:flex-row flex-col justify-between  h-full md:space-x-10 items-center mt-24 md:mt-10">
          <div className="flex flex-col md:w-1/2 w-full">
            <p className="font-bold md:text-xl text-lg">Order Detail :</p>

            <div class="flex flex-wrap  -mx-3 mb-6 mt-5">
              <div class="w-full px-3">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-orderId"
                >
                  Order Id
                </label>
                <input
                  name="orderId"
                  value={returnDetails.orderId}
                  onChange={handleChange}
                  className={` block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-amber-500 ${errors.orderid? "border-red-500" : ""}`}
                 
                  type="text"
                  placeholder="Enter Your Order Id"
                />
                  {errors.orderid && (
                    <p className="error text-sm text-red-500">
                      {errors.orderid}
                    </p>
                  )}
               
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-6 ">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-first-name"
                >
                  Product Name
                </label>
                <input
                name="product"
                value={returnDetails.product}
                onChange={handleChange}
                className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-amber-500 ${ errors.product ? "border-red-500" : ""}`}
                  id="grid-productname"
                  type="text"
                  placeholder="Necklace,Ring"
                />
                {errors.product && (
                    <p className="error text-sm text-red-500">
                      {errors.product}
                    </p>
                  )}
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-last-name"
                >
                  Product ID
                </label>
                <input
                name="productId"
                value={returnDetails.productId}
                onChange={handleChange}
                  className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-amber-500
                   ${ errors.productId ? "border-red-500" : ""}`}
                  id="grid-productId"
                  type="text"
                  placeholder="Enter Your product ID"
                />
                  {errors.productId && (
                    <p className="error text-sm text-red-500">
                      {errors.productId}
                    </p>
                  )}
              </div>
            </div>
          </div>

          <div className="flex flex-col   md:w-1/2 w-full ">
            <p className="font-bold md:text-xl text-lg">Personal Detail :</p>

            <div className="flex flex-wrap  -mx-3 mb-6 mt-5">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-first-name"
                >
                  Name
                </label>
                <input
                name="name"
                value={returnDetails.name}
                onChange={handleChange}
                 className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-amber-500 
                 ${ errors.name ? "border-red-500" : ""}`}
                  id="grid-productname"
                  type="text"
                  placeholder="Enter Yur Name"
                />
                {errors.name && (
                    <p className="error text-sm text-red-500">
                      {errors.name}
                    </p>
                  )}
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-last-name"
                >
                 Phone Number
                </label>
                <input
                name="phoneno"
                value={returnDetails.phoneno}
                onChange={handleChange}
                  className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-amber-500 
                  ${ errors.phoneno ? "border-red-500" : ""}`}
                  id="grid-productId"
                  type="text"
                  placeholder="Enter Your Phone Number"
                />
                  {errors.phoneno && (
                    <p className="error text-sm text-red-500">
                      {errors.phoneno}
                    </p>
                  )}
              </div>
            </div>

            <div className="flex flex-wrap -mx-3 mb-6 ">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-first-name"
                >
                  Email
                </label>
                <input
                name="email"
                value={returnDetails.email}
                onChange={handleChange}
                 className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-amber-500 
                 ${ errors.email ? "border-red-500" : ""}`}
                  id="grid-productname"
                  type="text"
                  placeholder="Enter Your Email"
                />
                {errors.email && (
                    <p className="error text-sm text-red-500">
                      {errors.email}
                    </p>
                  )}
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  for="grid-last-name"
                >
                Address
                </label>
                <input
                name="address"
                 value={returnDetails.address}
                 onChange={handleChange}
                 className={`appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-amber-500 
                 ${ errors.address? "border-red-500" : ""}`}
                  id="grid-productId"
                  type="text"
                  placeholder="Enter Your Address"
                />
                  {errors.address && (
                    <p className="error text-sm text-red-500">
                      {errors.address}
                    </p>
                  )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex mt-5 md:justify-end justify-center">
          <button className="px-5 py-2 text-white bg-primary rounded-md hover:bg-secondary text-xl" onClick={ handleSubmit}>
            Submit
          </button>
        </div>
      </form>
      </div>
    </div>
  );
};
