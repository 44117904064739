import { createSlice } from "@reduxjs/toolkit";

const relatedProductsSlice = createSlice({
    name: 'relatedProducts',
    initialState: {
        loading: false,
        relatedProducts: [],
        error: null,
    },
    reducers: {
        relatedProductsRequest(state, action){
            return {
                ...state,
                loading: true,
            }
        },
        relatedProductsSuccess(state, action){
            return {
                ...state,
                loading: false,
                relatedProducts: action.payload.data,
                
            }
        },

        relatedProductsFail(state, action){
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        },
    }
})

const { actions, reducer } = relatedProductsSlice;

export const { 
    relatedProductsRequest, 
    relatedProductsSuccess, 
    relatedProductsFail

} = actions;

export default reducer;