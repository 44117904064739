import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "./Sidebar";
import { useParams } from "react-router-dom";
const Upateprice = () => {
  const { id } = useParams();
  console.log(id);
  const [newprice, setNewprice] = useState();
  const [currentgoldprice, setCurrentgoldprice] = useState([]);

  console.log(newprice);

  useEffect(() => {
    const fetchCurrentPrice = async () => {
      try {
        const response = await axios.get(
          "https://igold.teammatrixmantra.com/goldprice/admin/show"
        );
        setCurrentgoldprice(response.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchCurrentPrice();
  }, []);

  // update the current  gold price in database
  const handlePriceUpdate = async (id) => {
    console.log(id);
    try {
      await axios.put(
        `https://igold.teammatrixmantra.com/goldprice/admin/update/${id}`,
        {
          id: id,
          goldprice: newprice,
        }
      );
    } catch (error) {
      console.error("Error updating product price:", error);
    }
  };

  return (
    <div className="flex">
      <Sidebar />
      <div className="h-screen flex-1 p-7 text-black overflow-auto md:overflow-x-hidden ">
        <div className="w-4/5 h-2/3 ">
          <h1 className="text-center font-bold text-xl">Today gold Price</h1>
          <Fragment>
            <div className="wrapper my-5 mx-4">
              {currentgoldprice.map((price) => (
                <form onSubmit={handlePriceUpdate(price._id)}>
                  <lable
                    htmlFor="name_field"
                    class="block mb-2 text-sm font-medium dark:text-gray-400"
                  >
                    Update today gold price
                  </lable>

                  <input
                    type="text"
                    id="goldprice"
                    placeholder={price.goldprice}
                    value={newprice}
                    onChange={(e) => setNewprice(e.target.value)}
                    className="block w-full px-4 py-3 mb-2 text-sm bg-gray-100 border font-normal rounded dark:placeholder-gray-400 dark:text-gray-400 dark:border-gray-800 dark:bg-gray-800"
                  />

                  <button
                    onClick={() => handlePriceUpdate(price._id)}
                    id="goldprice-btn"
                    type="submit"
                    className="mt-4 py-2 px-4 bg-blue-500 w-full text-white rounded-md hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-800"
                  >
                    Update
                  </button>
                </form>
              ))}
            </div>
          </Fragment>
        </div>
      </div>
    </div>
  );
};

export default Upateprice;
