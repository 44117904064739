import {useEffect, useState} from 'react';
import {useDispatch, useSelector } from 'react-redux'
import { register, clearAuthError } from '../../actions/userActions'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export default function Register() {
    const [userData, setUserData] = useState({
        name: "",
        email: "",
        password: ""
    });
    const [avatar, setAvatar] = useState("");
    const [avatarPreview, setAvatarPreview] = useState("/images/default_avatar.png");
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loading, error, isAuthenticated } = useSelector(state => state.authState)

    const onChange = (e) => {
        if(e.target.name === 'avatar') {
           const reader = new FileReader();
           reader.onload = () => {
                if(reader.readyState === 2) {
                    setAvatarPreview(reader.result);
                    setAvatar(e.target.files[0])
                }
           }


           reader.readAsDataURL(e.target.files[0])
        }else{
            setUserData({...userData, [e.target.name]:e.target.value })
        }
    }

    const submitHandler = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', userData.name)
        formData.append('email', userData.email)
        formData.append('password', userData.password)
        formData.append('avatar', avatar);
        dispatch(register(formData))
    }

    useEffect(()=>{
        if(isAuthenticated) {
            navigate('/');
            return
        }
        if(error)  {
            toast(error, {
                position: toast.POSITION.BOTTOM_CENTER,
                type: 'error',
                onOpen: ()=> { dispatch(clearAuthError) }
            })
            return
        }
    },[error, isAuthenticated, dispatch, navigate])

    return (
        <div className="flex wrapper">
            <div className="max-w-md w-10/12">
            <form onSubmit={submitHandler} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" encType='multipart/form-data'>
                <h1 className="mb-6 text-5xl font-bold">Register</h1>

            <div className="mb-4">
                <label htmlFor="name_field" className='block text-gray-700 text-xl font-bold mb-2'>Name</label>
                <input name='name' 
                onChange={onChange} 
                type="name" id="name_field" 
                className="block w-full px-4 py-2 mt-1 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" 
                />
            </div>

                <div className="mb-4">
                <label htmlFor="email_field" className='block text-gray-700 text-xl font-bold mb-2'>Email</label>
                <input
                    type="email"
                    id="email_field"
                    name='email' 
                    onChange={onChange}
                    className="block w-full px-4 py-2 mt-1 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" 
                  
                />
                </div>
    
                <div className="mb-4">
                <label htmlFor="password_field" className='block text-gray-700 text-xl font-bold mb-2'>Password</label>
                <input
                    name='password' 
                    onChange={onChange}
                    type="password"
                    id="password_field"
                    className="block w-full px-4 py-2 mt-1 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" 
                  
                />
                </div>

                     <div className="mb-4 ">
                        <label htmlFor='avatar_upload' className="block text-gray-700 text-xl font-bold mb-2">Profile Photo</label>
                        <div class="py-2 shrink-0">
                            <img className="object-cover w-16 h-16 rounded-full"
                                // src="https://i.postimg.cc/bNyr5cJq/pexels-anastasia-shuraeva-5704720.jpg"
                                src={avatarPreview}
                                alt="Current profile photo" />
                        </div>
                        <label className="block pt-2">
                            <span class="sr-only ">Choose profile photo</span>
                            <input type="file"
                                className="block w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:rounded file:border-0 file:text-sm file:font-semibold dark:file:bg-gray-600 dark:file:text-gray-200 dark:hover:file:bg-gray-700 file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100 " 
                                onChange={onChange}
                                name='avatar'
                                />
                        </label>
                    </div>
    
                <button
                id="register_button"
                type="submit"
                className="w-full py-3 bg-customOrange hover:bg-customOrange text-white font-bold rounded focus:outline-none focus:shadow-outline"
                disabled={loading}
                >
                REGISTER
                </button>
            </form>
            </div>
        </div>
    )
}