import { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getAdminProducts,deleteProduct } from "../../actions/productActions";
import Sidebar from "./Sidebar";
import { toast } from "react-toastify";
import Loader from "../Loader";
import { clearError, clearProductDeleted } from "../../slices/productSlice";
import Swal from "sweetalert2";
const ProductList = () => {
  const {
    products = [],
    loading = true,
    error,
  } = useSelector((state) => state.productsState);

  const {error : productError} =useSelector((state)=>state.productState) 
  const dispatch = useDispatch();




  useEffect(() => {
    if (error  || productError) {
      toast(error || productError, {
        position: toast.POSITION.BOTTOM_CENTER,
        type: "error",
        onOpen: () => {
          dispatch(clearError());
        },
      });
      return;
    }
  
    dispatch(getAdminProducts);
  }, [dispatch, error]);


  //delete function
  const handleDelete=(e,id)=>{
     
     Swal.fire({
          
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteProduct(id))
      }
    });
   
  }

  return (
    <div className="flex">
      <Sidebar />
      {loading ? (
        <Loader />
      ) : (
        <div className="h-screen flex-1 p-7 text-black overflow-auto md:overflow-x-hidden ">
          {/* <h1 className="text-2xl font-semibold ">Home Page</h1> */}
          <h1 className="my-4 text-4xl font-bold">All Products</h1>

          <div className="overflow-x-auto h-screen">
            <section className="items-center lg:flex bg-white font-poppins dark:bg-gray-800">
              <div className="justify-center flex-1 max-w-6xl px-4 py-4 mx-auto lg:py-8 md:px-6">
                <div className="pt-4 rounded shadow bg-stone-100 dark:bg-gray-900">
                  <div className="flex flex-wrap items-center justify-between px-6 pb-4 border-b dark:border-gray-700">
                    <h2 className="mb-4 text-xl font-bold md:mb-0 dark:text-gray-400">
                      List of All Products
                    </h2>
                    <div className="flex px-6 py-2 mb-4 border border-gray-600 rounded-md md:mb-0 dark:border-gray-400">
                      <input
                        type="text"
                        className="w-full pr-4 text-sm text-gray-700 bg-stone-100 dark:text-gray-400 dark:bg-gray-900 placeholder-text-100"
                        placeholder="search..."
                      />
                      <button className="flex items-center text-gray-700 dark:text-gray-400 dark:hover:text-blue-300 hover:text-blue-600">
                        <span className="mr-2 text-xs">Go</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-arrow-right"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="p-4 overflow-x-auto">
                    <table className="w-full table-auto">
                      <thead>
                        <tr className="text-sm text-left text-gray-500 dark:text-gray-400">
                          <th className="flex items-center px-6 pb-3 font-medium dark:text-gray-400">
                            <span>Product</span>
                          </th>
                          <th className="px-6 pb-3 font-medium">Name</th>
                          <th className="px-6 pb-3 font-medium">Making Rate</th>
                          <th className="px-6 pb-3 font-medium">Weight </th>
                          <th className="px-6 pb-3 font-medium">Stock </th>
                          <th className="px-6 pb-3 font-medium"> Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {products
                          .slice()
                          .reverse()
                          .map((product, index) => (
                            <tr
                              key={product.id}
                              className={`text-sm ${
                                index % 2 === 0
                                  ? "bg-white"
                                  : "dark:bg-gray-800"
                              } dark:text-gray-400`}
                            >
                              <td className="flex items-center px-6 py-5 font-medium mt-5">
                                {/* <p className="">{product._id}</p> */}
                                <img
                                  src={product.images[0].image}
                                  className="h-[50px] w-[50px]"
                                />
                              </td>
                              <td className="px-6 py-5 font-medium ">
                                {product.name}
                              </td>
                              <td className="px-6 py-5 font-medium ">
                                {product.makingrate}%
                              </td>
                              <td className="px-6 py-5 font-medium ">
                                {product.weight} g
                              </td>
                              <td className="px-6 py-5 font-medium">
                                {product.stock}
                              </td>
                              <td className="flex items-center px-6 py-5 mb-7 ">
                                <Link
                                to={`/admin/update/product/${product._id}`}
                                  className="font-medium text-blue-600 hover:text-blue-500 dark:hover:text-gray-300 dark:text-blue-300 mr-3 "
                                >
                                  <i class="fas fa-edit"></i>
                                </Link>
                                <button onClick={(e)=>handleDelete(e,product._id)}>
                                <a
                                  href="#"
                                  className="font-medium text-red-600 hover:text-red-500 dark:hover:text-red-300 dark:text-red-400"
                                >
                                  <i className="fas fa-trash"></i>
                                </a>
                                </button>
                               
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <div className="flex justify-end pt-4 mt-4 border-t dark:border-gray-700">
                      <nav aria-label="page-navigation">
                        <ul className="flex list-style-none">
                          <li class="page-item disabled ">
                            <a
                              href="#"
                              class="relative block pointer-events-none px-3 py-1.5 mr-3 text-base text-gray-700 transition-all duration-300  rounded-md dark:text-gray-400 hover:text-gray-100 hover:bg-blue-600"
                            >
                              Previous
                            </a>
                          </li>
                          <li class="page-item ">
                            <a
                              href="#"
                              class="relative block px-3 py-1.5 mr-3 text-base hover:text-blue-700 transition-all duration-300 hover:bg-blue-200 dark:hover:text-gray-400 dark:hover:bg-gray-700 rounded-md text-gray-100 bg-blue-600"
                            >
                              1
                            </a>
                          </li>
                          <li class="page-item ">
                            <a
                              href="#"
                              class="relative block px-3 py-1.5 text-base text-gray-700 transition-all duration-300 dark:text-gray-400 dark:hover:bg-gray-700 hover:bg-blue-100 rounded-md mr-3  "
                            >
                              2
                            </a>
                          </li>
                          <li class="page-item ">
                            <a
                              href="#"
                              class="relative block px-3 py-1.5 text-base text-gray-700 transition-all duration-300 dark:text-gray-400 dark:hover:bg-gray-700 hover:bg-blue-100 rounded-md mr-3 "
                            >
                              3
                            </a>
                          </li>
                          <li class="page-item ">
                            <a
                              href="#"
                              class="relative block px-3 py-1.5 text-base text-gray-700 transition-all duration-300 dark:text-gray-400 dark:hover:bg-gray-700 hover:bg-blue-100 rounded-md "
                            >
                              Next
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      )}
    </div>
  );
};
export default ProductList;
