import React from "react";
import axios from "axios";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { AllProducts } from "./sections/AllProducts";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../actions/productActions";
import { useEffect, useState } from "react";
import Loader from "../components/Loader";
import { toast } from "react-toastify";
import { loginSuccess, loginFail } from "../slices/authSlice";
import Swal from "sweetalert2";
import Productgrid from "./sections/Productgrid";
import { Link } from "react-router-dom";

const Products = () => {
  const [orderDetails, setOrderDetails] = useState({
    productName: "",
    productWeight: "",
  });
  const [currentgoldprice, setCurrentgoldprice] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  //get current price from databse
  useEffect(() => {
    const fetchCurrentPrice = async () => {
      try {
        const response = await axios.get(
          "https://igold.teammatrixmantra.com/goldprice/admin/show"
        );

        setCurrentgoldprice(response.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchCurrentPrice();
  }, []);

  console.log(currentgoldprice);

  const { subcategory } = useParams();

  // Use the useSelector hook to get the products state from Redux store

  const dispatch = useDispatch();
  const { products, loading, error } = useSelector(
    (state) => state.productsState
  );
  const { isAuthenticated } = useSelector((state) => state.authState);

  useEffect(() => {
    if (error) {
      return toast.error(error, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
    dispatch(getProducts);
  }, [error]);

  if (loading) {
    return <p>Loading...</p>;
  }

  // Check if error state exists
  if (error) {
    return <p>Error: {error.message}</p>;
  }

  console.log(products);

  // Convert products object to array

  // Check if productsArray is defined and an array before filtering
  if (!Array.isArray(products)) {
    console.error("Products is not an array.");
    return null; // Or handle the error appropriately
  }

  // Filter products based on the subcategory parameter
  const filteredProducts = products.filter(
    (product) => product.subcategory === subcategory
  );

  // Filter products based on the subcategory parameter
  // const filteredProducts = products.filter((product) => product.subcategory === subcategory);

  //send  product data to client mail

  const handleBuyNowClick = async (id) => {
    // setOrderDetails({
    //   productName: filteredProducts.name,
    //   productWeight:filteredProducts.weight,
    // })
    // console.log(orderDetails);
    // if(isAuthenticated){
    //   try {
    //     await axios.post("https://igold.teammatrixmantra.com/product/order", orderDetails)
    //     Swal.fire({
    //       icon: "success",
    //       title: "success...",
    //       text: "Email Send Succesfullly!",
    //     });
    //    } catch (error) {
    //     console.error("Error sending email:", error);
    //     Swal.fire({
    //       icon: "error",
    //       title: "Oops...",
    //       text: "Something went wrong!",
    //     });
    //    }
    // }
    // else{
    //   Swal.fire({
    //     title: "Please login?",
    //     text: "Without an account can't able to add card!",
    //     icon: "warning",
    //     showCancelButton: true,
    //     confirmButtonColor: "#3085d6",
    //     cancelButtonColor: "#d33",
    //     confirmButtonText: "Signup  Now!",
    //   })
    // }
  };
  return (
    <div>
      {/* <h2>{subcategory}</h2> */}
      {currentgoldprice.map((price) => (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 my-10">
          {filteredProducts.map((product, index) => (
            <div key={index} className="mb-10">
              <Link to={`/product/${product._id}`}>
                {/* Render product component with details from the product object */}
                {/* <img className="h-auto max-w-full rounded-lg" src={product.images[0].image} alt={product.name} /> */}
                <div className="max-w-md mx-auto rounded-md overflow-hidden shadow-md hover:shadow-lg">
                  <div className="relative lg:h-96 overflow-hidden">
                    <img
                      className="w-full"
                      src={product.images[0].image}
                      alt={product.name}
                    />
                    <div className="absolute top-0 right-0 bg-red-500 text-white px-2 py-1 m-2 rounded-md text-sm font-medium">
                      SALE
                    </div>
                  </div>
                  <div className="p-4">
                    <h3 className="text-lg font-medium mb-2">
                      {/* <Link to={`/product/${product._id}`}>{product.name}</Link> */}
                      {/* {product.name} */}
                    </h3>
                    <p className="text-gray-600 text-sm mb-4">
                      {product.description}
                    </p>

                    <div className="flex items-center justify-between">
                      <span className="font-bold text-lg">
                        Rs{" "}
                        {(price.goldprice / 8) * product.weight +
                          (price.goldprice * product.makingrate) / 100}
                      </span>

                      <button
                        className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded"
                        onClick={() => handleBuyNowClick(filteredProducts._id)}
                      >
                        {/* //
              <Productgrid key={product._id} product={product} goldprice={price}/>*/}
                        Buy Now
                      </button>
                    </div>
                  </div>
                </div>
              </Link>
              {/* <p>{product.name}</p>
            <p>{product.price}</p> */}
              {/* Add more details as needed */}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Products;
