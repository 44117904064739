import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { createOrder } from "../../actions/orderAction";
import { orderCompleted } from '../../slices/cartSlice';
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom';

export const Orderconfirm = () => {
    const {shippingInfo ,items:cartItems} = useSelector((state) => state.cartState)
    const {user} = useSelector((state) => state.authState)
    const dispatch = useDispatch()
    const navigate = useNavigate()
   


    const handleClick = () =>{
      try {
        const order = {
          orderItems: cartItems,
          shippingInfo,
          user: user._id
      };
      console.log(order);
       dispatch(createOrder(order))
       
       Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Your work has been saved",
        showConfirmButton: false,
        timer: 1500
      });
navigate('/')
      dispatch(orderCompleted())

        
      } catch (error) {
        Swal.fire({
  icon: "error",
  title: "Oops...",
  text: "Something went wrong!",
  footer: '<a href="#">Why do I have this issue?</a>'
});
      }

     
  
     
      
  
    }

  return (
    <div className="md:mt-10 container xl:max-w-6xl mx-auto px-2 py-4 lg:px-2 lg:py-4   h-auto">
        <div className='flex md:flex-row flex-col space-y-8  md:space-x-8 md:mt-2 mt-20'>
            <div className='bg-white shadow-2xl md:w-1/2 w-full px-10 py-5 mb-3 mt-5'>
                <h3 className='text-xl font-bold md:text-start text-center'>Your Information</h3>
                <hr />
             {}
              <div className='flex flex-col space-y-3 mt-5'>
              <p>Name: {shippingInfo.fullname}</p>
              <hr />
              <p>phone No: {shippingInfo.phone_No}</p>
              <hr />
              <p>Email: {shippingInfo.email}</p>
              <hr />
              <p>House No : {shippingInfo.house_No}</p>
              <hr />
              <p>Street : {shippingInfo.street}</p>
              <hr />
              <p>City: {shippingInfo.city}</p>
              <hr />
              <p>District: {shippingInfo.district}</p>
              <hr />

              </div>
            </div>
            <div className='bg-white shadow-xl md:w-1/2  w-full px-10 py-5'>
            <h3 className='text-xl font-bold md:text-start text-center'>Order Information</h3>
                <hr />
                {cartItems.map((item) =>(

  <div className='mt-5 flex flex-row space-x-5'>
  <img src={item.image} alt='product' className=' h-24 w-24 ' />

  <div >
  <p > Product Name: {item.name}</p>
  <p> Weight: {item.weight}</p>
  <p> Quantity: {item.quantity}</p>
  </div>

</div>
                ))}


              
            </div>
           
        </div>
        <div className='mt-5 flex items-center justify-center'>
              <button className='bg-primary px-3 w-48 py-3 text-white font-semibold hover:bg-secondary text-xl rounded-lg' 
              onClick={() => handleClick()}
              >Confirm</button>
            </div>
       
        </div>
  )
}
