import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
// Import Swiper styles
import "swiper/swiper-bundle.css";
import "./slider.css"; // You can define your own slider styles
import { EffectFade } from "swiper/modules";
import Hero1 from "../../images/hero1.webp";
import Hero2 from "../../images/hero2.webp";
import Hero3 from "../../images/hero3.webp";

const Hero = () => {
  return (
    <Swiper
      className="hero-slider"
      modules={[Navigation, Pagination, Scrollbar, A11y, EffectFade, Autoplay]}
      spaceBetween={50}
      effect="fade"
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      loop={true}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
    >
      <SwiperSlide>
        <div className="slide">
          <img
            src={Hero1}
            alt="Image 1"
            className="slide-img"
            style={{ position: "relative", width: "100%" }}
          />
          <div className="text-overlay">
            <p className="new-comings pb-10">NEW COMINGS</p>
            <h2 className="font-bodoni">Amazing Jewellery Collections</h2>
            <button class="inline-block cursor-pointer rounded-md bg-white px-8 py-4 text-center text-xl font-semibold text-black transition duration-200 ease-in-out hover:bg-gray-900 hover:text-white">
              Shop
            </button>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <img src={Hero2} alt="" style={{ width: "100%" }} />
      </SwiperSlide>
      <SwiperSlide>
        <img src={Hero3} alt="" style={{ width: "100%" }} />
      </SwiperSlide>
    </Swiper>
  );
};

export default Hero;
